<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="back-button mt-0">
                            <router-link class="back-button__icon" :to="{name : 'company.setting.user'}">
                                <i class="eicon e-back"></i>
                            </router-link>
                            <div class="back-button__text">
                                {{ $t(`Update user`) }}
                                <v-popover offset="10" :disabled="false" style="display: initial;">
                                    <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i></button>
                                    <template slot="popover">
                                        <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                        <div style="text-align:left;">
                                            <a v-close-popover href="https://easy.jobs/docs/add-team-member" target="_blank">
                                                {{ $t(`How to add team member?`) }}
                                            </a> <br/>
                                            <a href="https://easy.jobs/docs/manage-team-visibility" target="_blank">
                                                {{ $t(`How to manage team visibility?`) }}
                                            </a>
                                        </div>
                                    </template>
                                </v-popover>
                            </div>
                        </div>
                    </div>
                    <div class="form-box feature-image-box custom-fields">
                        <div class="form-group row row-cols-md-2">
                            <div class="col">
                                <label>{{ $t("Email") }}*</label>
                                <input type="email" name="email" id="" :value="form.email" class="form-control" placeholder="user@easy.jobs" readonly/>
                            </div>
                            <div class="col">
                                <label>{{ $t("Role") }}*</label>
                                <div class="select-option">
                                    <select v-model="form.role_id" @change="getRolePermissions()" v-bind:class="{ 'has-error': errors.role_id.length }" :disabled="data.isOwner">
                                        <option value="0" disabled="" selected="">{{ $t("select role") }}</option>
                                        <option v-for="(role,index) in roles" :value="index">{{ role }}</option>
                                    </select>
                                </div>
                                <div class="error-message" v-text="errors.role_id"></div>
                            </div>
                        </div>
                        <div class="custom-field-tab-wrap">
                            <div class="accordion ej-accordion" id="accordionExample">
                                <div class="card" v-for="(permission,index) in permissions">
                                    <div class="card-header" :id="`headingOne-${index+1}`">
                                        <button class="btn btn-link btn-block text-left" type="button"
                                                data-toggle="collapse" :data-target="`#collapseOne-${index+1}`"
                                                aria-expanded="true" aria-controls="collapseOne">
                                            {{ permission.name }}
                                        </button>
                                    </div>
                                    <div :id="`collapseOne-${index+1}`" class="collapse show"
                                         aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">
                                            <div class="notification__settings">
                                                <div class="settings__item"
                                                     v-for="(per, index) in permission.permissions">
                                                    <div class="item__content">
                                                        <h5 class="mb-1"><i
                                                                :class="`eicon ${per.icon}`"></i>{{ per.name }}</h5>
                                                        <p>{{ per.hint }}</p>
                                                    </div>
                                                    <div class="item__switch">
                                                        <label class="ej-switch">
                                                            <input type="checkbox" v-model="per.selected"
                                                                   :disabled="per.dependency_lock"
                                                                   @change="permissionSelected(per)">
                                                            <span class="switch__btn">
                                                                <span class="on">On</span>
                                                                <span class="off">Off</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="error-message" style="margin-left: 20px;" v-text="errors.selected"></div>
                        </div>
                        <div class="d-flex justify-content-between mt-3">
                            <router-link :to="{ name: 'company.setting.user' }"
                                         class="button semi-button-info text-capitalize">{{ $t("back") }}
                            </router-link>
                            <submit-button :click="updateManager" :loading="isLoading">{{ $t("Update") }}</submit-button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>


<script>
import client from "../../../app/api/Client";
import _ from 'lodash';
import {mapActions, mapState} from 'vuex';
import {UPDATE_COMPANY_PERMISSION, UPDATE_USER_DATA} from "../../../constants/action-type";
import {stringConvertSpan} from "../../../extra/helper";
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },
    data() {
        return {
            data:[],
            form: {
                email : '',
                role_id : 0,
                selected :[],
            },
            errors : {
                email : '',
                role_id : '',
                selected : ''
            },
            roles: [],
            permissions : [],
            permitted : [],
            user : null,
            selected :[],
            allSelected: 0,
            isLoading: false,
            rolePermissions : [],
            currentTab: 1,
            allPermissions: [],
            dependencyLocks: ['job.publish', 'job.management', 'candidate.organize', 'candidate.delete']
        }
    },
    computed: {
        ...mapState(['company']),
    },
    methods : {
        ...mapActions([UPDATE_USER_DATA, UPDATE_COMPANY_PERMISSION]),

        async getUsers() {
            this.isContentLoading = true;
            let userId = this.$route.params.id;
            try {
                let {data: {data}} = await client().get('/company/setting/user');
                let userData = data.find((user) => {
                    return user.id == userId;
                });
                if(typeof userData == 'undefined') {
                    this.$toast.error("The user not found.");
                    this.goUserPermissionListPage();
                }
                this.data = userData;
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        async updateManager(){
            this.form.selected = this.selected;
            if(this.isValid()){
                this.isLoading = true;
                try {
                    const {data : { data, message }} = await client().post(`company/setting/user/${this.user}/update`,this.form);

                    if(!_.isEmpty(data.user)) {
                        this[UPDATE_USER_DATA](data.user);

                        const currentCompany = data.user.companies.find((company) => {
                            return this.company.id === company.id;
                        });

                        if (this.user === data.user.id) {
                            this[UPDATE_COMPANY_PERMISSION](currentCompany.permission);
                        }
                    }
                    this.$toast.success(this.$t(message));
                    await this.$router.push({name: 'company.setting.user'});
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }finally {
                    this.isLoading = false;
                }
            } else {
                this.$toast.error(this.$t('Please fill all required fields'));
            }
        },
        getRolePermissions(){
            if(this.form.role_id == this.data.roleId) {
                this.selected = this.data.permissions;
            } else {
                this.selected = this.rolePermissions.hasOwnProperty(this.form.role_id)? this.rolePermissions[this.form.role_id]:[];
            }
            this.checkSelected(true);

        },
        getRolesPermissions(){
            client().get('setting/role-permissions').then(({data : { data }}) => {
                this.roles = data.roles;
                this.permissions = data.permissions;
                this.rolePermissions = data.rolePermissions;
                this.permissions.forEach(p => {
                    p.permissions.forEach(pr => {
                        this.allPermissions.push(pr);
                    });
                });
                this.checkSelected(true);
            });

        },
        isValid() {
            this.errors.email = "";
            this.errors.role_id = "";
            this.errors.password_confirmation = "";


            if (this.form.email.length < 1) {
                this.errors.email = this.$t("Email field is required");
                return false;
            }

            if (!_helper.validateEmail(this.form.email)) {
                this.errors.email = this.$t("Please type a valid email address");
                return false;
            }

            if (this.form.role_id < 1 ) {
                this.errors.role_id = this.$t("Please select a role");
                return false;
            }

            if (this.form.selected.length < 1) {
                this.currentTab=1;
                this.errors.selected = this.$t("Please select some permissions");
                return false;
            }
            return true;
        },
        stringConvertToSpan(name) {
            return stringConvertSpan(name);
        },
        permissionSelected(permission) {
            if (this.selected.includes(permission.key)) {
                if (this.hasDependencyLock(permission.key)) {
                    this.$toast.error('Contains dependency lock');
                } else {
                    this.selected = this.selected.filter(per => permission.key != per);
                    if (permission.dependencies.length) {
                        this.unlockDependencies(permission.dependencies);
                    }
                }
            } else {
                let keys = [permission.key];
                if (permission.dependencies.length) {
                    keys = keys.concat(permission.dependencies);
                    this.lockDependencies(permission.dependencies);
                }
                this.selected = this.selected.concat(keys);
                this.selected = _.uniq(this.selected);
            }

            this.checkSelected();

        },
        hasDependencyLock(key) {
            let noDependecy = false;
            this.permissions.forEach((per) => {
                per.permissions.forEach(p => {
                    if (this.selected.includes(p.key)) {
                        if (p.dependencies.includes(key)) {
                            noDependecy = true;
                        }
                    }
                });
            });

            return noDependecy;
        },
        checkSelected(check = false) {
            this.permissions = this.permissions.map((per) => {
                per.permissions = per.permissions.map(p => {
                    p.selected = this.selected.includes(p.key);
                    if(check) {
                        if(p.selected) {
                            this.lockDependencies(p.dependencies);
                        } else {
                            this.unlockDependencies(p.dependencies);
                        }
                    }
                    return p;
                });
                return per;
            });
        },
        lockDependencies(keys) {
            this.permissions = this.permissions.map((per) => {
                per.permissions = per.permissions.map(p => {
                    if (keys.includes(p.key)) {
                        p.dependency_lock = true;
                    }
                    return p;
                });
                return per;
            });
        },
        unlockDependencies(keys) {
            this.permissions = this.permissions.map((per) => {
                per.permissions = per.permissions.map(p => {
                    if (keys.includes(p.key) && !this.hasOtherDependencyLock(p.key)) {
                        p.dependency_lock = false;
                    }
                    return p;
                });
                return per;
            });
        },
        hasOtherDependencyLock(key) {
            let result = false;
            let permission = this.allPermissions.filter(p => p.dependencies.includes(key));
            permission.forEach(p =>{
                if(this.selected.includes(p.key)) {
                    result = true;
                }
            })

            return result;
        }
    },
    async mounted() {
        await this.getUsers();
        this.form.email = this.data.email;
        this.form.role_id = this.data.roleId;
        this.user = this.data.id;
        this.selected = this.data.permissions;
        await this.getRolesPermissions();
    }
};
</script>

<style scoped>
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell {
    padding: 15px;
}
.page-body .content-area__body .data-table .table-wrap .table .table__row .table-cell p {
    font-size: 16px;
}
.page-body .content-area__body .data-table .table-wrap .table {
    border-spacing: 2px 5px;
}
.ej-switch {
    display: table-caption;
}
.flat__table .table__row .table-cell.cell-email,
.flat__table .table__row .table-cell.cell-push {
    display: flex;
    align-items: center;
}
.flat__table .table__row .table-cell.cell-email .checkbox,
.flat__table .table__row .table-cell.cell-push .checkbox {
    height: 21px;
    margin-right: 7px;
}

@media all and (max-width: 1600px) {
    .ej-switch .switch__btn {
        height: 27px;
        width: 58px;
    }
    .ej-switch .switch__btn::before {
        height: 17px;
        width: 17px;
    }
    .ej-switch input:checked ~ .switch__btn::before {
        left: 35px;
    }
}


.ej-row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
}

.ej-col-3 {
    grid-column: span 4;
}

.custom-fields .custom-field-card {
    height: 100% !important;
    margin-bottom: 0;

}

.ej-row .custom-field-card {
    box-shadow: 0 10px 10px rgba(89, 125, 252, .3);
    border: 0;
}


.ej-accordion .card {
    border-radius: 5px;
}

.ej-accordion .card:not(:last-child) {
    margin-bottom: 20px;
}

.ej-accordion .card:not(:first-of-type) {
    border-radius: 5px;
}

.ej-accordion .card:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-radius: 5px;
}

.ej-accordion .card .card-header {
    padding: 0;
    background: #fff;
}

.ej-accordion .card .card-header .btn {
    font-size: 18px;
    padding: 13px 30px 13px 20px;
    color: #212529;
    text-decoration: none;
    font-weight: 500;
    position: relative;
}

.ej-accordion .card .card-header .btn i {
    color: #7d8091;
    margin-right: 10px;
}

.ej-accordion .card .card-header .btn::before {
    position: absolute;
    top: 13px;
    right: 15px;
    content: '\e805';
    font-family: 'eicon';
    color: #7d8091;
    transition: transform 0.3s ease;
}

.ej-accordion .card .card-header .btn.collapsed::before {
    transform: rotate(-180deg);
}

.ej-accordion .card .card-body p {
    color: #7d8091;
    font-size: 14px;
    font-weight: 400;
}

.notification__settings .settings__item {
    display: flex;
}

@media all and (max-width: 439px) {
    .notification__settings .settings__item {
        display: block;
    }
}

.notification__settings .settings__item:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.notification__settings .settings__item:not(:first-child) {
    padding-top: 20px;
}

.notification__settings .settings__item .item__content {
    flex: 1 1 auto;
    padding-right: 15px;
}

@media all and (max-width: 439px) {
    .notification__settings .settings__item .item__content {
        padding-right: 0;
        padding-bottom: 15px;
    }
}

.notification__settings .settings__item .item__content h5 {
    font-size: 15px;
    font-weight: 500;
}

.notification__settings .settings__item .item__content h5 i {
    margin-right: 8px;
    font-size: 16px;
}

.notification__settings .settings__item .item__content p {
    font-size: 14px;
}

.notification__settings .settings__item .item__switch {
    flex: 0 0 65px;
}

.ej-switch input:disabled ~ .switch__btn {
    background: #a5f1bf;
    cursor: no-drop;
}

@media all and (max-width: 990px) {
    .feature-image-box>.row {
        margin-bottom: 10px !important;
    }
}
</style>
